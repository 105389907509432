exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-alt-tsx": () => import("./../../../src/pages/about-alt.tsx" /* webpackChunkName: "component---src-pages-about-alt-tsx" */),
  "component---src-pages-about-tech-tsx": () => import("./../../../src/pages/about-tech.tsx" /* webpackChunkName: "component---src-pages-about-tech-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-showcase-pricing-tsx": () => import("./../../../src/pages/showcase/pricing.tsx" /* webpackChunkName: "component---src-pages-showcase-pricing-tsx" */),
  "component---src-pages-showcase-sectors-tsx": () => import("./../../../src/pages/showcase/sectors.tsx" /* webpackChunkName: "component---src-pages-showcase-sectors-tsx" */),
  "component---src-pages-showcase-tsx": () => import("./../../../src/pages/showcase.tsx" /* webpackChunkName: "component---src-pages-showcase-tsx" */),
  "component---src-templates-about-about-page-js": () => import("./../../../src/templates/about/AboutPage.js" /* webpackChunkName: "component---src-templates-about-about-page-js" */),
  "component---src-templates-blog-blog-author-page-js": () => import("./../../../src/templates/blog/BlogAuthorPage.js" /* webpackChunkName: "component---src-templates-blog-blog-author-page-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-blog-blog-post-list-js": () => import("./../../../src/templates/blog/BlogPostList.js" /* webpackChunkName: "component---src-templates-blog-blog-post-list-js" */),
  "component---src-templates-sectors-sector-page-js": () => import("./../../../src/templates/sectors/SectorPage.js" /* webpackChunkName: "component---src-templates-sectors-sector-page-js" */)
}

